<template>
  <!-- Discover View -->
  <MainLayout
    v-scroll="onScroll"
    scroll-toolbar
    :bg-color="selectedTab === 'ads' ? '' : 'white'"
    class="relative"
  >
    <template #toolbar>
      <div class="flex flex-col w-full">
        <!-- Top header -->
        <div class="relative flex items-center justify-between p-3 border-b border-border-normal">
          <!-- Page label, ad counter -->
          <div class="flex items-center gap-3">
            <img
              src="../../assets/images/discovery-new.png"
              class="w-8 h-8"
            >
            <BaseText
              type="label"
              size="sm"
              class="text-text-muted"
            >
              Discovery
            </BaseText>
            <!-- Ad Counter -->
            <div class="flex p-0.5 rounded-md bg-neutral-25">
              <BaseText
                type="body"
                size="xs"
                class="text-text-normal px-1.5 py-1"
              >
                Ad Count
              </BaseText>
              <div class="counter-container px-1.5 rounded bg-white">
                <BaseLoadingSpinnerCircle
                  v-if="!getDiscoveryAdCount"
                  :width="18"
                  :height="18"
                  class="text-icon-normal opacity-30"
                  style="margin-top: 2.5px"
                  :duration="0.9"
                />
                <BaseAnimatedCounter
                  v-else
                  class="text-text-muted text-xs font-semibold"
                  :value="getDiscoveryAdCount"
                  :line-height="24"
                  animate-on-mount
                />
              </div>
            </div>
          </div>

          <!-- Discovery Search Bar -->
          <div class="absolute flex top-1/2 transform -translate-y-1/2" style="left: 308px; right: 308px; z-index: 40001">
            <div class="relative flex-grow mx-auto min-w-0 h-8" style="max-width: 600px">
              <DiscoverySearch 
                :text-search.sync="textSearch"
                :brands-only="selectedTab === 'brands'"
                @submit="handleSearchSubmit"
              />
            </div>
          </div>

          <!-- Create New Button -->
          <div class="flex items-center">
            <BaseSelect
              no-shadow
              no-padding
              white-background
              text-trigger
              close-dropdown
              popper-center
              item-value="name"
              :items="[
                { name: 'Save New Ad', icon: 'plus', action: openAdModal },
                { name: 'Manual Upload', icon: 'upload', action: openManualUpload },
                { name: 'Create Board', icon: 'board', action: () => showManageBoardModal = true },
                { name: 'Create Folder', icon: 'folder', action: () => showFolderModal = true },
                { name: 'Create Brief', icon: 'brief', action: () => $router.push({ name: 'BriefsView' }).catch(() => {}) },
                { name: 'Add Spyder', icon: 'spyder', action: () => {$router.push({ name: 'SpyderBrandsView' })} },
              ]"
              @select="() => {}"
              @input="() => {}"
            >
              <div slot="reference">
                <BaseButton
                  primary
                  class
                  @click="showCreateBriefModal = true"
                >
                  <div class="flex items-center justify-between w-full h-full">
                    <img
                      src="../../assets/icons/library-create-icon.svg"
                      class="w-5 h-5 mr-1.5 -ml-1"
                    >
                    <BaseText
                      type="label"
                      size="sm"
                    >
                      Create New
                    </BaseText>
                  </div>
                </BaseButton>
              </div>
              <template #item="{ item }">
                <div class="flex items-center w-full h-full">
                  <img
                    v-if="item.icon === 'plus'"
                    src="../../assets/icons/plus-icon.svg"
                    :alt="item.name"
                    class="w-6 h-6 mr-1.5 -ml-px"
                  >
                  <svg
                    v-if="item.icon === 'upload'"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    class="w-5 h-5 mr-1.5"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.99999 3.125V12.5M9.99999 3.125L13.75 6.875M9.99999 3.125L6.25 6.875M16.875 10.625V15.2083C16.875 16.1288 16.1288 16.875 15.2083 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083V10.625"
                      stroke="#5E6678"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <img
                    v-if="item.icon === 'board'"
                    src="../../assets/icons/create-board-icon.svg"
                    :alt="item.name"
                    class="w-5 h-5 mr-1.5"
                  >
                  <img
                    v-if="item.icon === 'folder'"
                    src="../../assets/icons/create-folder-icon.svg"
                    :alt="item.name"
                    class="w-5 h-5 mr-1.5"
                  >
                  <img
                    v-if="item.icon === 'brief'"
                    src="../../assets/icons/document-gray.svg"
                    :alt="item.name"
                    class="w-5 h-5 mr-1.5"
                  >
                  <svg
                    v-if="item.icon === 'spyder'"
                    width="15"
                    height="20"
                    viewBox="0 0 15 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 mr-1.5 text-icon-normal"
                  >
                    <path
                      d="M7.4774 14.6967V19.0996C7.4774 19.0996 3.8498 16.1644 2.20665 13.6058C0.192936 10.4846 0.222191 8.67941 0.222191 8.67941L2.57234 7.78902C2.57234 7.86238 2.57234 7.94068 2.57234 8.01894C2.61267 9.87949 3.33879 11.6591 4.61041 13.0138C5.55634 14.0607 6.66315 14.6967 7.4774 14.6967Z"
                      fill="currentColor"
                    />
                    <path
                      d="M14.7374 8.67912C14.7374 8.67912 14.7666 10.4844 12.7529 13.5713C11.1098 16.1054 7.47726 19.0651 7.47726 19.0651V14.6622C8.34519 14.6622 9.42758 14.0507 10.3589 13.0429C11.6386 11.6804 12.3699 9.89046 12.4116 8.0187C12.4116 7.94044 12.4116 7.86703 12.4116 7.79367L14.7374 8.67912Z"
                      fill="currentColor"
                    />
                    <path
                      d="M7.47738 0.900574V5.93949L2.57231 7.7887L0.222168 8.67905C0.222168 8.67905 2.07498 0.900574 7.47738 0.900574Z"
                      fill="currentColor"
                    />
                    <path
                      d="M7.47738 0.900574C12.8115 0.900574 14.7374 8.67912 14.7374 8.67912L12.4116 7.79367L7.47738 5.93949V0.900574Z"
                      fill="currentColor"
                    />
                    <path
                      d="M5.10724 7.77576C5.1543 7.65645 5.32316 7.65645 5.37022 7.77576L5.61345 8.3925C5.64218 8.46535 5.69985 8.52302 5.77271 8.55175L6.38944 8.79499C6.50875 8.84205 6.50875 9.0109 6.38944 9.05796L5.77271 9.3012C5.69985 9.32993 5.64218 9.3876 5.61345 9.46045L5.37022 10.0772C5.32316 10.1965 5.1543 10.1965 5.10724 10.0772L4.86401 9.46045C4.83528 9.3876 4.77761 9.32993 4.70475 9.3012L4.08802 9.05796C3.96871 9.0109 3.96871 8.84205 4.08802 8.79499L4.70475 8.55175C4.77761 8.52302 4.83528 8.46535 4.86401 8.3925L5.10724 7.77576Z"
                      fill="currentColor"
                    />
                    <path
                      d="M10.5739 8.81699L10.4695 8.7795C10.1656 8.67032 9.84509 8.6145 9.52216 8.6145C9.19923 8.6145 8.87874 8.67032 8.57483 8.7795L8.47046 8.81699C8.36141 8.85617 8.36141 8.99674 8.47046 9.03591L8.57483 9.07341C8.87874 9.18258 9.19923 9.2384 9.52216 9.2384C9.84509 9.2384 10.1656 9.18258 10.4695 9.07341L10.5739 9.03591C10.6829 8.99674 10.6829 8.85617 10.5739 8.81699Z"
                      fill="currentColor"
                    />
                  </svg>
                  <div
                    class="flex items-center cursor-pointer"
                    @click="item.action"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </template>
            </BaseSelect>
          </div>
        </div>
        <!-- Subview Tabs -->
        <div
          class="flex gap-2 w-full px-3 py-2"
          :class="{'border-b border-border-normal': selectedTab === 'experts'}"
        >
          <router-link
            :to="{ name: 'DiscoveryView'}"
            class="brand-view-tab"
            :class="{'active': selectedTab === 'ads'}"
            style="width: 134px"
          >
            Community Feed
            <transition name="tab">
              <div
                v-if="selectedTab === 'ads'"
                class="brand-tab-underline"
              />
            </transition>
          </router-link>
          <router-link
            :to="{ name: 'DiscoveryBrandsView' }"
            class="brand-view-tab"
            :class="{'active': selectedTab === 'brands'}"
            style="width: 67px"
          >
            Brands
            <transition name="tab">
              <div
                v-if="selectedTab === 'brands'"
                class="brand-tab-underline"
              />
            </transition>
          </router-link>
          <router-link
            :to="{ name: 'DiscoveryExpertsView' }"
            class="brand-view-tab"
            :class="{'active': selectedTab === 'experts'}"
            style="width: 71px"
          >
            Experts
            <transition name="tab">
              <div
                v-if="selectedTab === 'experts'"
                class="brand-tab-underline"
              />
            </transition>
          </router-link>
        </div>
      </div>
    </template>

    <!-- Filter Bar -->
    <template v-if="['ads', 'brands'].includes(selectedTab)" #filter>
      <div ref="filterBar" class="border border-border-normal bg-white bg-opacity-90" style="backdrop-filter: blur(6px);">
        <FilterTopBar
          v-if="selectedTab === 'ads'"
          ref="filterTopBar"
          no-search
          :keyword-search-opened="openKeywordSearch"
          :alphabetical="false"
          :selected-formats.sync="selectedFormats"
          :selected-platforms.sync="selectedPlatforms"
          :selected-niches.sync="selectedNiches"
          :selected-content-filters.sync="selectedContentFilters"
          :selected-market-targets.sync="selectedMarketTargets"
          :selected-date-range.sync="selectedDateRange"
          :sort-order.sync="sortOrder"
          :live-status.sync="liveStatus"
          @change="fetchAdvertisements"
          @resetKeywordSearch="openKeywordSearch = false"
        />
        <FilterTopBar
          v-else-if="selectedTab === 'brands'"
          ref="filterTopBar"
          no-search
          alphabetical
          discovery-brands
          :selected-niches.sync="selectedNiches"
          :favorite-filter.sync="favoriteFilter"
          :sort-order.sync="sortOrder"
          @change="fetchBrands"
        />
      </div>
    </template>

    <template #content>
      <div v-if="selectedTab === 'ads'">
        <!-- Loading State -->
        <BaseLoadingLogo
          v-if="loadingAdvertisements || loadingShuffledAds"
          class="mt-24"
        />

        <!-- Empty State With Filters -->
        <div
          v-else-if="!advertisements.length"
          class="flex flex-col items-center mt-20"
        >
          <img
            src="../../assets/images/empty-with-filters.gif"
            class="border border-gray-400 rounded mx-auto object-cover"
            style="width: 400px; height: 300px"
          >

          <div class="text-lg font-bold my-4">
            Too many filters
          </div>

          <div class="text-center text-gray-700">
            No ads found that meet your current filters
          </div>

          <div class="flex mt-5">
            <BaseButton
              primary
              @click="clearFilters"
            >
              Clear filters
            </BaseButton>
          </div>
        </div>

        <!-- Advertisements List -->
        <AdvertisementList
          v-else
          :discovery="true"
          :advertisements="advertisements"
          :loaded="!loadingAdvertisements"
          @updateAds="updateAdsLocal"
          @getMoreAds="handleMoreAdsRequest"
          @reload="fetchAdvertisements(false)"
        />

        <infinite-loading
          v-if="advertisements.length"
          :identifier="infiniteId"
          @infinite="handleInfiniteLoading"
        >
          <div slot="spinner">
            <BaseLoadingLogo :margin="2" />
          </div>
          <div slot="no-more" />
          <div slot="no-results" />
        </infinite-loading>
        <transition name="shuffleFade">
          <ShuffleButton
            :can-be-shuffled="isAnySelected"
            @shuffle="fetchShuffledAdvertisements(false)"
            @update:selectedLanguages="updateSelectedLanguages"
            @open-keyword-search="toggleKeywordSearchMethod"
          />
        </transition>
      </div>

      <DiscoveryExperts v-else-if="$route.name === 'DiscoveryExpertsView'" />

      <DiscoveryBrands
        v-else
        :text-search="textSearch"
        :update-query="shouldUpdateQuery"
        :favorite-filter="favoriteFilter"
        :selected-niches="selectedNiches"
        :sort-order="sortOrder"
      />

      <!-- Create Advertisement Modal -->
      <CreateAdvertisementModal
        v-if="showAdvertisementModal"
        :starting-tab="manualUpload ? 'manual' : 'extension'"
        @close="showAdvertisementModal = false"
        @save="fetchAdvertisements(false)"
      />

      <!-- Manage Board Modal -->
      <ManageBoardModal
        v-if="showManageBoardModal"
        :board="{}"
        @close="showManageBoardModal = false"
      />

      <TutorialModal
        v-if="showTutorialModal"
        @close="showTutorialModal = false"
      />

      <FolderModal
        v-if="showFolderModal"
        @close="showFolderModal = false"
      />
    </template>
  </MainLayout>
</template>

<script>
import FirebaseAPI from '@/api/firebase'
import InfiniteLoading from 'vue-infinite-loading'
import { mapActions, mapGetters } from 'vuex'

import ForeplayAPI from '@/api/foreplayServer'
import firebase from '../../api/config/FirebaseConfig'
import ManageBoardModal from '../../components/boards/ManageBoardModal'
import AdvertisementList from '../../components/advertisements/AdvertisementList'
import CreateAdvertisementModal from '../../components/advertisements/AdvertisementCreateModal'
import MainLayout from '../../layouts/MainLayout'
import DiscoverySearch from '../../components/discovery/DiscoverySearch.vue'
import DiscoveryBrands from '../../components/discovery/DiscoveryBrands.vue'
import DiscoveryExperts from '../../components/discovery/DiscoveryExperts.vue'
import TutorialModal from '../../components/advertisements/TutorialModal'
import FilterTopBar from '../../components/search/FilterTopBar.vue'
import FolderModal from '../../components/sidebar/FolderModal.vue'
import ShuffleButton from './ShuffleButton.vue'

export default {
  name: 'DiscoveryView',
  components: {
    ShuffleButton,
    AdvertisementList,
    CreateAdvertisementModal,
    DiscoverySearch,
    DiscoveryBrands,
    DiscoveryExperts,
    FilterTopBar,
    FolderModal,
    InfiniteLoading,
    TutorialModal,
    MainLayout,
    ManageBoardModal
  },
  data () {
    return {
      niches: [],
      advertisements: [],
      loadingAdvertisements: false,
      loadingShuffledAds: false,
      showManageBoardModal: false,
      showAdvertisementModal: false,
      showFolderModal: false,
      showTutorialModal: false,
      manualUpload: false,
      filtersApplied: false,
      selectedFormats: [],
      selectedNiches: [],
      selectedContentFilters: [],
      selectedLanguages: [],
      selectedMarketTargets: [],
      openKeywordSearch: false,
      selectedDateRange: {
        start: null,
        end: null
      },
      liveStatus: [],
      sortOrder: [],
      selectedPlatforms: [],
      lastDocId: null,
      infiniteId: +new Date(),
      selectedTab: '', // Intitialized in mounted
      docOrder: 'desc',

      // Search Specific
      searchMode: false,
      searchPage: 0,
      searchQuery: '',
      textSearch: '',
      infiniteLoaderState: null,

      // Brands Section
      favoriteFilter: false,
      shouldUpdateQuery: new Date().toString(),
      isShuffled: false
    }
  },
  computed: {
    ...mapGetters('AuthModule', ['getUser']),
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('RouterModule', ['getLastRoute']),
    ...mapGetters('MiscModule', ['getTheme', 'getDiscoveryAdCount']),
    ...mapGetters('AuthModule', [
      'getTeam',
      'isFreeTier',
      'getUserFreeTrialDate',
      'getUser'
    ]),
    ...mapGetters('AdvertisementsModule', ['getAdsBeingSaved']),
    ...mapGetters('TagsModule', ['getTags']),
    isAnySelected () {
      return (
        this.selectedFormats.length > 0 ||
        this.selectedNiches.length > 0 ||
        this.selectedLanguages.length > 0 ||
        this.selectedPlatforms.length > 0 ||
        this.textSearch !== ''
      )
    }
  },
  created () {
    if (this.$route.query.search) {
      this.textSearch = this.$route.query.search
    }
  },
  async mounted () {
    this.selectedLanguages = this.getUser.defaultLanguages || []

    if (this.$route.name === 'DiscoveryBrandsView') {
      this.selectedTab = 'brands'
      this.sortOrder = [{ name: 'Ads Saved', value: 'saved' }]
      this.niches = await this.getNiches()
    } else if (this.$route.name === 'DiscoveryExpertsView') {
      this.selectedTab = 'experts'
    } else {
      this.selectedTab = 'ads'
      this.sortOrder = [{ name: 'Newest', value: 'desc' }]
      await this.fetchAdvertisements()
      const runAfter = async () => {
        this.niches = await this.getNiches()
        await this.getUsersTags()
        await this.fetchTags()
      }

      runAfter()
    }

    // Set initial top bar styles
    if (this.$refs.filterBar) {
      this.$refs.filterBar.style.position = 'sticky'
      this.$refs.filterBar.style.top = '16px'
      this.$refs.filterBar.style.zIndex = '40000'
    }
  },
  methods: {
    // ================================================================================
    // ================================= DATA METHODS =================================
    // ================================================================================

    async getUsersTags () {
      let userTags

      if (this.getTeam) {
        userTags = await FirebaseAPI.Tags.getAllByTeam(this.getTeam.id)
      } else {
        userTags = await FirebaseAPI.Tags.getAllByUser()
      }

      return userTags
    },
    async getNiches () {
      // Setup
      const db = firebase.firestore()

      const snapshot = await db.collection('niches').get()

      const newDocs = snapshot.docs?.map((doc) => {
        const docData = {
          ...(doc.data() || [])
        }

        return { name: docData.niche, icon: docData.emoji }
      })

      const sortedDocs = newDocs.sort((a, b) => (a.name > b.name ? 1 : -1))
      const otherIndex = sortedDocs.findIndex((d) => d.name === 'Other')
      const otherNiche = sortedDocs.splice(otherIndex, 1)

      sortedDocs.push(otherNiche[0])

      return sortedDocs
    },
    handleSearchSubmit () {
      this.updateRouteQuery()
      if (this.selectedTab === 'ads') {
        this.fetchAdvertisements()
      } else {
        this.fetchBrands()
      }
    },
    updateRouteQuery () {
      if (!this.textSearch.length) {
        window.history.replaceState({}, '', this.$route.path)
        return
      }
      // Update the route's search query parameter with the current textSearch value
      const params = new URLSearchParams(window.location.search)
      params.set('search', this.textSearch)
      const newUrl = `${this.$route.path}?${params.toString()}`
      window.history.replaceState({}, '', newUrl)
    },
    handleInfiniteLoading ($state) {
      if (this.isShuffled) {
        this.getMoreShuffledAds($state)
      } else {
        this.getMoreAds($state)
      }
    },
    handleMoreAdsRequest () {
      if (this.isShuffled) {
        this.getMoreShuffledAds({})
      } else {
        this.getMoreAds({})
      }
    },
    async getMoreAds ($state) {
      console.log('this.isShuffled', this.isShuffled)
      console.log('fetching more regular ads')
      if (this.trialExpired) {
        return
      }
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      const savedBetweenStart = +new Date(this.selectedDateRange.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange.end) || null
      const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(this.lastDocId, {
        savedBetweenStart,
        savedBetweenEnd,
        textSearch: this.textSearch,
        sort: this.sortOrder[0].value,
        orFilters: {
          formats: this.selectedFormats,
          niches: this.selectedNiches,
          contentFilters: this.selectedContentFilters,
          publisher_platform: this.selectedPlatforms,
          liveStatus: this.liveStatus,
          languages: this.selectedLanguages,
          marketTarget: this.selectedMarketTargets
        }
      })

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.advertisements = this.advertisements.concat(results)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    async getMoreShuffledAds ($state) {
      console.log('this.isShuffled', this.isShuffled)
      console.log('getting more shuffled ads')
      if (this.trialExpired) {
        return
      }
      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      const savedBetweenStart = +new Date(this.selectedDateRange.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange.end) || null
      const { results, nextPage } = await ForeplayAPI.Ads.getShuffledDiscoveryAds(this.lastDocId, {
        savedBetweenStart,
        savedBetweenEnd,
        textSearch: this.textSearch,
        sort: this.sortOrder[0].value,
        orFilters: {
          formats: this.selectedFormats,
          niches: this.selectedNiches,
          contentFilters: this.selectedContentFilters,
          publisher_platform: this.selectedPlatforms,
          liveStatus: this.liveStatus,
          languages: this.selectedLanguages,
          marketTarget: this.selectedMarketTargets
        }
      })

      this.lastDocId = nextPage

      if (!this.lastDocId) {
        if (Object.keys($state).length) {
          $state.complete()
        }
        return
      }

      this.advertisements = this.advertisements.concat(results)
      if (Object.keys($state).length) {
        $state.loaded()
      }
    },
    // Handle Clear Filters
    async clearFilters () {
      const filterTopBar = this.$refs.filterTopBar
      filterTopBar.clearAllFilters()

      await this.fetchAdvertisements()

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    async fetchShuffledAdvertisements (load = true) {
      this.loadingShuffledAds = true
      console.log('fetching ads via fetchShuffledAdvertisements')
      this.advertisements = []
      this.loadingAdvertisements = load

      const savedBetweenStart = +new Date(this.selectedDateRange?.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange?.end) || null
      try {
        const { results, nextPage } = await ForeplayAPI.Ads.getShuffledDiscoveryAds(null, {
          savedBetweenStart,
          savedBetweenEnd,
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters: {
            formats: this.selectedFormats,
            niches: this.selectedNiches,
            contentFilters: this.selectedContentFilters,
            publisher_platform: this.selectedPlatforms,
            liveStatus: this.liveStatus,
            languages: this.selectedLanguages,
            marketTarget: this.selectedMarketTargets
          }
        })

        this.lastDocId = nextPage
        this.advertisements = results
        this.loadingShuffledAds = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1
      }
      this.isShuffled = true
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },
    // Fetch Advertisements
    async fetchAdvertisements (load = true) {
      console.log('fetching ads via fetchAdvertisements')
      this.advertisements = []
      this.loadingAdvertisements = load

      const savedBetweenStart = +new Date(this.selectedDateRange?.start) || null
      const savedBetweenEnd = +new Date(this.selectedDateRange?.end) || null
      try {
        const { results, nextPage } = await ForeplayAPI.Ads.getDiscoveryAds(null, {
          savedBetweenStart,
          savedBetweenEnd,
          textSearch: this.textSearch,
          sort: this.sortOrder[0].value,
          orFilters: {
            formats: this.selectedFormats,
            niches: this.selectedNiches,
            contentFilters: this.selectedContentFilters,
            publisher_platform: this.selectedPlatforms,
            liveStatus: this.liveStatus,
            languages: this.selectedLanguages,
            marketTarget: this.selectedMarketTargets
          }
        })

        this.lastDocId = nextPage
        this.advertisements = results
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdvertisements = false
        this.infiniteId += 1
      }
      this.isShuffled = false

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 600)
    },
    updateAdsLocal (adsToUpdate) {
      console.log('updating ads', adsToUpdate)
      const duplicateAds = [...this.advertisements]

      adsToUpdate.forEach((ad) => {
        const index = duplicateAds.findIndex((a) => a.id === ad.id)

        if (index !== -1) {
          duplicateAds[index] = ad
        }
      })

      this.advertisements = duplicateAds
    },

    // ================================================================================
    // ================================== UI METHODS ==================================
    // ================================================================================

    toggleKeywordSearchMethod () {
      console.log('openKeywordSearch triggered:', this.openKeywordSearch)
      this.openKeywordSearch = !this.openKeywordSearch
    },
    ...mapActions('BoardsModule', ['fetchBoards']),
    ...mapActions('TagsModule', ['fetchTags']),
    fetchBrands () {
      this.shouldUpdateQuery = new Date().toString()
    },
    updateSelectedLanguages (newSelectedLanguages) {
      this.selectedLanguages = newSelectedLanguages
      console.log('selectedLanguages in DiscoveryView:', this.selectedLanguages)

      this.fetchAdvertisements()
    },
    openManualUpload () {
      this.manualUpload = true
      this.showAdvertisementModal = true
    },
    openAdModal () {
      this.manualUpload = false
      this.showAdvertisementModal = true
    },
    startPosition (position) {
      this.$refs.bottomShadow.classList.add(`start${position}`)
    },
    onScroll (e, position) {
      const marginValue =
        position.scrollTop / 6 < 16 ? position.scrollTop / 6 : 16
      const rounding =
        position.scrollTop / 10 < 5 ? position.scrollTop / 10 : 5
      if (this.$refs.filterBar) {
        this.$refs.filterBar.style.marginLeft = `${marginValue}px`
        this.$refs.filterBar.style.marginRight = `${marginValue}px`
        this.$refs.filterBar.style.marginTop = `${marginValue}px`
        this.$refs.filterBar.style.borderRadius = `${rounding}px`
      }
      const shadowSection = (maxValue) => {
        return position.scrollTop / 15 < maxValue
          ? position.scrollTop / 15
          : maxValue
      }

      if (position.scrollTop && this.$refs.filterBar) {
        this.$refs.filterBar.style.boxShadow = `rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        15
      )}px ${shadowSection(55)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        7
      )}px ${shadowSection(
        10
      )}px, rgba(0, 0, 0, 0.08) 0px 4px 6px, rgba(0, 0, 0, 0.08) 0px ${shadowSection(
        7
      )}px ${shadowSection(13)}px, rgba(0, 0, 0, 0.08) 0px -${shadowSection(
        3
      )}px ${shadowSection(5)}px`
      // this.$refs.filterBar.style.boxShadow = `rgba(58, 111, 251, 0.4) -${shadowSection(5)}px ${shadowSection(5)}px, rgba(58, 111, 251, 0.3) -${shadowSection(10)}px ${shadowSection(10)}px, rgba(58, 111, 251, 0.2) -${shadowSection(15)}px ${shadowSection(15)}px, rgba(58, 111, 251, 0.1) -${shadowSection(20)}px ${shadowSection(20)}px, rgba(58, 111, 251, 0.05) -${shadowSection(25)}px ${shadowSection(25)}px`
      } else {
        if (this.$refs.filterBar) {
          this.$refs.filterBar.style.boxShadow = null
        }
      }
    },
    moveShadowRight () {
      this.$refs.bottomShadow.classList.add('moveRight')

      this.$refs.bottomShadow.classList.remove('moveLeft')
      this.$refs.bottomShadow.classList.remove('moveCenter')
    },
    moveShadowCenter () {
      this.$refs.bottomShadow.classList.add('moveCenter')

      this.$refs.bottomShadow.classList.remove('moveLeft')
      this.$refs.bottomShadow.classList.remove('moveRight')
    },
    moveShadowLeft () {
      this.$refs.bottomShadow.classList.add('moveLeft')

      this.$refs.bottomShadow.classList.remove('moveRight')
      this.$refs.bottomShadow.classList.remove('moveCenter')
    },
    updateFilter (filterName, items) {
      this[filterName] = items
    },
    toggleFavoriteFilter () {
      this.favoriteFilter = !this.favoriteFilter
    }
  },
  metaInfo: {
    title: 'Foreplay.co | Discovery'
  }
}
</script>

<style scoped>
.brand-view-tab {
  position: relative;
  padding: 0.375rem 0.625rem;
  border-radius: 0.375rem;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #5E6678; /* text-text-normal */
  background-color: transparent;
  cursor: pointer;
  user-select: none; /* Standard */
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out;
  white-space: nowrap;
}
.brand-view-tab:hover {
  color: #303546; /* text-text-muted */
  background-color: #F6F8FA; /* bg-neutral-25 */
}
.brand-view-tab.active {
  color: #303546; /* text-text-muted */
  background-color: transparent;
  font-weight: 500;
}
.brand-tab-underline {
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 0px;
  z-index: 40001;
  border-bottom: 1px solid #06070F; /* border-icon-loud */
}
.counter-container {
  box-shadow: 0px 1px 1px -0.5px rgba(26, 48, 84, 0.04), 0px 2px 2px -1px rgba(26, 48, 84, 0.03), 0px 3px 3px -1.5px rgba(26, 48, 84, 0.03), 0px 5px 5px -2.5px rgba(26, 48, 84, 0.02), 0px 8px 8px -4px rgba(26, 48, 84, 0.02);
}

.shadow-elem {
  display: flex;
  justify-content: center;
  width: 34%;
}

.tool-el {
  /* Right */
  --right-width: 25%;
  --right-translate: 72%;

  /* Center */
  --center-width: 25%;
  --center-translate: 39%;

  /* Left */
  --left-width: 34%;
  --left-translate: 0%;
}

/* Right */
.moveRight {
  animation: moveRightAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.moveRight div {
  animation: rightWidthAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.startRight {
  transform: translateX(var(--right-translate));
}

.startRight div {
  width: var(--right-width)
}

/* Center */
.moveCenter {
  animation: moveCenterAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.moveCenter div {
  animation: centerWidthAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.startCenter {
  transform: translateX(var(--center-translate));
}

.startCenter div {
  width: var(--center-width)
}

/* Left */
.moveLeft {
  animation: moveLeftAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.moveLeft div {
  animation: leftWidthAnimation 0.5s ease-out;
  animation-fill-mode: forwards;
}

.startLeft {
  transform: translateX(var(--left-translate));
}

.startLeft div {
  width: var(--left-width)
}
.shuffleFade-enter-active, .shuffleFade-leave-active {
  transition: opacity 0.3s;
}
.shuffleFade-enter, .shuffleFade-leave-to {
  opacity: 0;
}

/* Rigth Animations */
@keyframes moveRightAnimation {
  to {
    transform: translateX(var(--right-translate));
  }
}

@keyframes rightWidthAnimation {
  to {
    width: var(--right-width)
  }
}

/* Center Animations */
@keyframes moveCenterAnimation {
  to {
    transform: translateX(var(--center-translate));
  }
}

@keyframes centerWidthAnimation {
  to {
    width: var(--center-width)
  }
}

/* Left Animations */
@keyframes moveLeftAnimation {
  to {
    transform: translateX(var(--left-translate));
  }
}

@keyframes leftWidthAnimation {
  to {
    width: var(--left-width)
  }
}

/* Vue <transition> classes */
.tab-enter-active, .tab-leave-active {
  transition: width 150ms ease-in-out;
}
.tab-enter-from, .tab-enter, .tab-leave-to {
  width: 0;
}
.tab-enter-to, .tab-leave-from {
  width: 100%;
}
</style>
